/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFaultEventLog } from '../../actions/devices';

import './style.css';
import { getCriticalDeviceDataApiCall } from '../../services/devices';

import { DataTable } from '../Utils/reusableTable';
import { PropagateLoader } from 'react-spinners';

export default function ViewFaultEventLog() {
  const faultEventLogs = useSelector((state) => state.devices.criticalData) || [];

  const dispatch = useDispatch();

  const [totalCount, setTotalCount] = useState(undefined);
  const [downloading, setDownloading] = useState(false);
  const [ready, setReady] = useState(false);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);

  const abortControllerRef = useRef(new AbortController());

  const faultEventLogColumns = [
    "Real_Name", "date", "Ongoing_fault", "Extended_Ongoing_fault", "PrefaultCurrent", "FaultCurrent", 
    "RecoverCurrent","EventType","Event_Record_ID", "EventID", "RecordID", "IMEINumber", "IndexID",
    "DM1_Event_ID", "DM1_EventType", "DM1_PhaseAEventStatus", "DM1_PhaseBEventStatus", "DM1_PhaseCEventStatus", "DM1_Event_Record_ID"
  ] 
  const overrideNotToDisplay = [
    "EventID", "IMEINumber", "IndexID", "RecoverCurrent", "Extended_Ongoing_fault", "DM1_PhaseAEventStatus", 
    "DM1_PhaseBEventStatus", "DM1_PhaseCEventStatus","Event_Record_ID", "DM1_Event_ID", "DM1_EventType", "DM1_Event_Record_ID"
  ]

  useEffect(() => {
    if (faultEventLogs.length){
      const { TotalCount } = faultEventLogs[0];
      setTotalCount(Number(TotalCount));
      setReady(true);
    } else {
      setTotalCount(0);
    }
  }, [faultEventLogs]);

  useEffect(() => {
    const fetchData = async () => {
      const apiRes = await getCriticalDeviceDataApiCall(page, pageSize);
      setReady(true);
      await dispatch(getFaultEventLog(apiRes.data));
    };

    fetchData();
  }, [dispatch, page, pageSize]);

  const onDownloadClick = useCallback(
    async (from, to) => {
      setDownloading(true);
      const res = await getCriticalDeviceDataApiCall(
        0,
        totalCount,
        abortControllerRef.current.signal,
        from,
        to,
      );
      setDownloading(false);
      return res.data.map((d) => {
        delete d['GeoCoords'];
        return d;
      });
    },
    [totalCount],
  );

  return (
    <>
      { 
        !ready ? (<PropagateLoader className="Center mt-4" size={10} color="#000" speedMultiplier={0.7} />) :
      (
        <DataTable
          usePaper={true}
          displayColumns={faultEventLogColumns}
          tableData={faultEventLogs}
          buttonColumns={[]}
          buttonsData={{}}
          enableDownloadCSV={true} 
          csvFileName='fault_event_log.csv'
          onDownloadClick={onDownloadClick}
          page={page}
          setPage={setPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          totalCount={totalCount}
          overrideNotToDisplay={overrideNotToDisplay}
        />
      )
      }
    </>
  );
}
