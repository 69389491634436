export const SET_USER = 'SET_USER';
export const SET_ADMIN_USERS = 'SET_ADMIN_USERS';
export const SET_ADMIN_GROUPS = 'SET_ADMIN_GROUPS';
export const SET_ADMIN_DEVICES = 'SET_ADMIN_DEVICES';

// UI actions

export const TOGGLE_ADMIN_SUB_MENU = 'TOGGLE_ADMIN_SUB_MENU';

// Device data
export const SET_CRITICAL_DEVICEDATA = 'SET_CRITICAL_DEVICEDATA';
export const SET_DATALOG_DEVICEDATA = 'SET_DATALOG_DEVICEDATA';
export const SET_DATALOG_DEVICEDATA_BY_ID = 'SET_DATALOG_DEVICEDATA_BY_ID';
export const SET_DEVICES = 'SET_DEVICES';
export const SET_DEVICES_COUNT = 'SET_DEVICES_COUNT';
export const SET_MAP_DATA = 'SET_MAP_DATA';
export const SET_MAP_DATA_LOADER = 'SET_MAP_DATA_LOADER';

export const SET_CRITICAL_DEVICEDATA_COLUMNS = 'SET_CRITICAL_DEVICEDATA_COLUMNS';
export const SET_DATALOG_DEVICEDATA_COLUMNS = 'SET_DATALOG_DEVICEDATA_COLUMNS';
export const SET_DATALOG_DEVICEDATA_COUNT = 'SET_DATALOG_DEVICEDATA_COUNT';

export const SET_EVENTLOG_DEVICEDATA_BY_IMEI = 'SET_EVENTLOG_DEVICEDATA_BY_IMEI';
export const SET_DEVICE_LOAD_HISTORY = 'SET_DEVICE_LOAD_HISTORY';
