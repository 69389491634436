import {
  SET_CRITICAL_DEVICEDATA,
  SET_DATALOG_DEVICEDATA,
  SET_DEVICES,
  SET_DEVICES_COUNT,
  SET_DATALOG_DEVICEDATA_BY_ID,
  SET_MAP_DATA,
  SET_DATALOG_DEVICEDATA_COLUMNS,
  SET_CRITICAL_DEVICEDATA_COLUMNS,
  SET_DATALOG_DEVICEDATA_COUNT,
  SET_MAP_DATA_LOADER,
  SET_EVENTLOG_DEVICEDATA_BY_IMEI,
  SET_DEVICE_LOAD_HISTORY,
} from '../constants/action-types';
const initialDeviceDataState = {
  criticalData: [],
  criticalDataCol: [],
  datalog: undefined,
  dataLogCount: 0,
  datalogCol: [],
  devices: [],
  devicesCount: undefined,
  datalogById: [],
  mapData: [],
  mapDataFetched: false,
  eventlogByIMEI: {},
  loadhistory: { loadHistory: [], totalCount: 0 }
};

const reducer = (state = initialDeviceDataState, action) => {
  switch (action.type) {
    case SET_CRITICAL_DEVICEDATA: {
      return { ...state, criticalData: action.payload };
    }
    case SET_CRITICAL_DEVICEDATA_COLUMNS: {
      return { ...state, criticalDataCol: action.payload };
    }

    case SET_DATALOG_DEVICEDATA: {
      return { ...state, datalog: action.payload };
    }
    case SET_DATALOG_DEVICEDATA_COLUMNS: {
      return { ...state, datalogCol: action.payload };
    }
    case SET_DATALOG_DEVICEDATA_COUNT: {
      return { ...state, dataLogCount: action.payload };
    }
    case SET_DATALOG_DEVICEDATA_BY_ID: {
      return { ...state, datalogById: action.payload };
    }
    case SET_DEVICES: {
      return { ...state, devices: action.payload };
    }
    case SET_DEVICES_COUNT: {
      return { ...state, devicesCount: action.payload };
    }
    case SET_MAP_DATA: {
      return { ...state, mapData: action.payload };
    }

    case SET_MAP_DATA_LOADER: {
      return { ...state, mapDataFetched: action.payload };
    }
    case SET_EVENTLOG_DEVICEDATA_BY_IMEI: {
      return { ...state, eventlogByIMEI: action.payload };
    }
    case SET_DEVICE_LOAD_HISTORY: {
      return { ...state, loadhistory: action.payload };
    }
    default: {
      return state;
    }
  }
};
export { reducer as DeviceDataReducer };
